import { useState } from "react";
import styled from "styled-components";
import AddPlantModal from "./AddPlantModal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import generateAPI from "../utils/api";
import { Button, Popconfirm, Space, Table } from "antd";
import Search from "antd/es/input/Search";
import { ColumnsType } from "antd/es/table/Table";

const Container = styled.div``;

interface PlantDataObject {
  _id: string;
  name: string;
  category: string;
  modified: number;
}

export function PlantList() {
  const [search, setSearch] = useState("");
  const [addPlantVisible, setAddPlantVisible] = useState(false);
  const queryClient = useQueryClient();
  const api = generateAPI();

  const { refetch, data: plants } = useQuery("plants", () =>
    api.admin.listPlants.get(),
  );

  const removePlantMutation = useMutation(
    ({ id }: { id: string }) =>
      api.admin.removePlant.post({
        id,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["plants"]);
      },
    }
  );

  // if (!plants) return null;

  const searchFilter = new RegExp(search, "i");
  const filtered = plants
    ?.filter((plant: PlantDataObject) => searchFilter.test(plant.name))
    .sort((a: PlantDataObject, b: PlantDataObject) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  const columns: ColumnsType<PlantDataObject> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: PlantDataObject, b: PlantDataObject) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        } else {
          return 1;
        }
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sortDirections: ["ascend", "descend"],
      sorter: (a: PlantDataObject, b: PlantDataObject) => {
        if (a.category.toLowerCase() < b.category.toLowerCase()) {
          return -1;
        } else {
          return 1;
        }
      },
    },
    {
      title: "Controls",
      key: "_id",
      dataIndex: "_id",
      render: (id: string, row: PlantDataObject) => (
        <>
          <Popconfirm
            title={`Are you sure you wish to remove ${row.name}?`}
            okText={"Yes"}
            onConfirm={async () => {
              await removePlantMutation.mutate({ id });
            }}
          >
            <Button danger={true} onClick={() => {}}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];


  return (
    <Container>
      {addPlantVisible && (
        <AddPlantModal
          onComplete={refetch}
          handleClose={() => setAddPlantVisible(false)}
        />
      )}

      <Space>
        <Search
          placeholder={"Search"}
          value={search}
          onChange={(evt) => setSearch(evt.target.value)}
        />
        <Button type={"primary"} onClick={() => setAddPlantVisible(true)}>
          Add plant
        </Button>
      </Space>

      <Table rowKey={"name"} dataSource={filtered} columns={columns} />
    </Container>
  );
}
