import { Header } from "./components/Header";
import { PlantList } from "./components/PlantList";
import useAccount from "./hooks/useAccount";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";

export function PlantListPage() {
  const { token, dialog, logout } = useAccount();
  return (
    <Layout>
      {dialog}
      {token && (
        <>
          <Header selected={"plants"} />
          <Content style={{ padding: 20 }}>
            <Title level={3}>Plant List</Title>
            <PlantList />
          </Content>
        </>
      )}
    </Layout>
  );
}
