import { Header } from "./components/Header";
import useAccount from "./hooks/useAccount";
import { TipsList } from "./components/TipsList";
import { Button, Layout, Popconfirm, Space, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import { useMutation, useQuery, useQueryClient } from "react-query";
import generateAPI from "./utils/api";
import { ColumnsType } from "antd/es/table/Table";
import AddTipModal from "./components/AddTipModal";
import Search from "antd/es/input/Search";
import { useState } from "react";
import AddVideoModal from "./components/AddVideoModal";
import {serverURL} from "./utils";

interface VideoType {
  title: string;
  url: string;
  thumbnail: string;
  /** Duration in seconds */
  duration: number;
  time: number;
}

export function VideoPage() {
  const { token, dialog, logout } = useAccount();
  const api = generateAPI();
  const [search, setSearch] = useState("");
  const [addVideoVisible, setAddVideoVisible] = useState(false);

  const queryClient = useQueryClient();

  const { refetch, data: videos } = useQuery("videos", () =>
    api.video.list.get()
  );

  const removeVideo = useMutation(
    ({ id }: { id: string }) =>
      api.video.remove.post({
        id,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["videos"]);
      },
    }
  );

  console.log("vi", videos);

  const columns: ColumnsType<VideoType> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      sortDirections: ["ascend", "descend"],
      sorter: (a: VideoType, b: VideoType) => {
        if (a.time < b.time) {
          return -1;
        } else {
          return 1;
        }
      },
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Controls",
      key: "_id",
      dataIndex: "_id",
      render: (id: string) => (
        <>
          <Popconfirm
            title={`Are you sure you wish to remove this video?`}
            okText={"Yes"}
            onConfirm={async () => {
              await removeVideo.mutate({ id });
            }}
          >
            <Button danger={true} onClick={() => {}}>
              Delete
            </Button>
          </Popconfirm>{" "}
          <Button onClick={() => document.location=`${serverURL}/video/watch/${id}.mp4`}>
            Watch
          </Button>
        </>
      ),
    },
  ];

  return (
    <Layout>
      {dialog}
      {token && (
        <>
          <Header selected={"videos"} />
          <Content style={{ padding: 20 }}>
            <Title level={3}>Videos</Title>

            <>
              {addVideoVisible && (
                <AddVideoModal
                  onComplete={refetch}
                  handleClose={() => setAddVideoVisible(false)}
                />
              )}
              <Space>
                <Search
                  placeholder={"Search"}
                  value={search}
                  onChange={(evt) => setSearch(evt.target.value)}
                />
                <Button
                  type={"primary"}
                  onClick={() => setAddVideoVisible(true)}
                >
                  Add video
                </Button>
              </Space>
              <Table<VideoType>
                rowKey={"name"}
                dataSource={videos}
                columns={columns}
              />
            </>
          </Content>
        </>
      )}
    </Layout>
  );
}
