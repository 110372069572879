import { useState } from "react";
import generateAPI from "../utils/api";
import { Form, Modal, Input } from "antd";
import { useQueryClient } from "react-query";

interface props {
  handleClose: () => void;
  onComplete: () => void;
}

export default function AddVideoModal({ handleClose, onComplete }: props) {
  const [title, setTitle] = useState("");
  const [duration, setDuration] = useState(0);
  const [url, setUrl] = useState("");
  const queryClient = useQueryClient();

  const api = generateAPI();

  return (
    <Modal
      visible={true}
      onCancel={handleClose}
      title={"Add Video"}
      onOk={async () => {
        if (!title || !url) {
          return;
        }

        await api.video.add.post({ title, url, duration });
        await queryClient.invalidateQueries(["videos"]);

        onComplete();
        handleClose();
      }}
    >
      <Form>
        <Form.Item label={"Title"}>
          <Input
            placeholder="Enter title"
            value={title}
            onChange={(evt) => setTitle(evt.target.value)}
          />
        </Form.Item>

        <Form.Item label={"Duration (seconds)"}>
          <Input
            placeholder="Enter duration"
            value={duration}
            type={"number"}
            min={0}
            onChange={(evt) => setDuration(Number(evt.target.value))}
          />
        </Form.Item>
        <Form.Item label={"URL"}>
          <Input
            placeholder="Enter URL"
            value={url}
            onChange={(evt) => setUrl(evt.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
