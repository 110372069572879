import { Dialog } from "../components/Dialog";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useStore } from "./useStore";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import generateAPI from "../utils/api";

const Container = styled.div``;

export default function useAccount() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const token: string|null = useStore((state) => state.token);
  const setToken = useStore((state) => state.setToken);
  const api = generateAPI();

  useEffect(() => {
    async function check() {
      if (!token) return;

      try {
        const userData = await api.user.me.get();

        if (userData.error) {
          setToken(null);
        } else {
          setToken(userData.token);
        }
      } catch (e) {
        setToken(null);
      }
    }

    check();
  }, []);

  const logout = () => setToken(null);

  const dialog = !token ? (
    <Dialog
      isVisible={true}
      body={
        <Container>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(evt) => setPassword(evt.target.value)}
              />
            </Form.Group>

            <Button
              variant="primary"
              onClick={async () => {
                const { token } = await api.user.login.post({
                  email,
                  password,
                });

                if (token) {
                  setToken(token);
                }
              }}
            >
              Login
            </Button>
          </Form>
        </Container>
      }
    />
  ) : null;

  return { token, dialog, logout };
}
