import styled from "styled-components";
import { Header as AntHeader } from "antd/es/layout/layout";
import { Menu } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";

const Container = styled(AntHeader)`
  background-color: #fff;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const Logo = styled.div`
  flex-direction: row;
  align-items: center;
  float: left;
  width: 120px;
  height: 31px;
  margin: 4px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
`;

const menuItems = [
  {
    name: "Plant List",
    key: "plants",
    url: "/",
  },
  {
    name: "Tips",
    key: "tips",
    url: "/tips",
  },
  {
    name: "Users",
    key: "users",
    url: "/users",
  },{
    name: "Videos",
    key: "videos",
    url: "/videos",
  },
  {
    name: "Discover",
    key: "discover",
    url: "/discover",
  },
];

export function Header({ selected = "plants" }) {
  return (
    <Container>
      <Logo>
        <Title>Nibble Admin</Title>
      </Logo>
      <Menu
        onClick={({ key }) => {
          const link = menuItems.find((link) => link.key === key);
          if (link) {
            document.location = link.url;
          }
        }}
        selectedKeys={[selected]}
        mode="horizontal"
      >
        {menuItems.map((item) => (
          <Menu.Item key={item.key} icon={<UnorderedListOutlined />}>
            {item.name}
          </Menu.Item>
        ))}
      </Menu>
    </Container>
  );
}
