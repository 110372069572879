import { useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AddTipModal from "./AddTipModal";
import generateAPI from "../utils/api";
import { Button, Popconfirm, Space, Table } from "antd";
import Search from "antd/es/input/Search";
import {ColumnsType} from "antd/es/table/Table";

const Container = styled.div``;

interface TipDataObject {
  _id: string;
  content: string;
  time: number;
}

export function TipsList() {
  const [search, setSearch] = useState("");
  const [addTipVisible, setAddTipVisible] = useState(false);
  const queryClient = useQueryClient();

  const { refetch, data: tips } = useQuery("tips", () =>
    generateAPI().admin.listTips.get()
  );

  const removeTip = useMutation(
    ({ id }: { id: string }) =>
      generateAPI().admin.removeTip.post({
        id,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["tips"]);
      },
    }
  );

  const columns : ColumnsType<TipDataObject> = [
    {
      title: "Tip",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      sortDirections: ["ascend", "descend"],
      sorter: (a: TipDataObject, b: TipDataObject) => {
        if (a.time < b.time) {
          return -1;
        } else {
          return 1;
        }
      },
    },
    {
      title: "Controls",
      key: "_id",
      dataIndex: "_id",
      render: (id: string) => (
        <>
          <Popconfirm
            title={`Are you sure you wish to remove this tip?`}
            okText={"Yes"}
            onConfirm={async () => {
              await removeTip.mutate({ id });
            }}
          >
            <Button danger={true} onClick={() => {}}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <Container>
      {addTipVisible && (
        <AddTipModal
          onComplete={refetch}
          handleClose={() => setAddTipVisible(false)}
        />
      )}
      <Space>
        <Search
          placeholder={"Search"}
          value={search}
          onChange={(evt) => setSearch(evt.target.value)}
        />
        <Button type={"primary"} onClick={() => setAddTipVisible(true)}>
          Add tip
        </Button>
      </Space>
      <Table<TipDataObject> rowKey={"name"} dataSource={tips} columns={columns} />
    </Container>
  );
}
