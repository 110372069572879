import { Header } from "./components/Header";
import useAccount from "./hooks/useAccount";
import { TipsList } from "./components/TipsList";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";

export function TipsPage() {
  const { token, dialog, logout } = useAccount();

  return (
    <Layout>
      {dialog}
      {token && (
        <>
          <Header selected={"tips"} />
          <Content style={{ padding: 20 }}>
            <Title level={3}>Tips</Title>
            <TipsList />
          </Content>
        </>
      )}
    </Layout>
  );
}
