import { useState } from "react";
import generateAPI from "../utils/api";
import { Form, Input, Modal, Select } from "antd";

const plantTypes = [
  "legumes",
  "whole-grains",
  "nuts-seeds",
  "herbs-spices",
  "fruits",
  "vegetables",
];

const api = generateAPI();

interface props {
  handleClose: () => void;
  onComplete: () => void;
}

export default function AddPlantModal({ handleClose, onComplete }: props) {
  const [name, setName] = useState("");
  const [type, setType] = useState("legumes");

  return (
    <Modal
      visible={true}
      onCancel={handleClose}
      title={"Add Plant"}
      onOk={async () => {
        if (!name || !type) {
          return;
        }

        await api.admin.newPlant.post({
          name,
          category: type,
        });

        onComplete();
        handleClose();
      }}
    >
      <Form>
        <Form.Item label={"Plant name"}>
          <Input
            type="text"
            placeholder="Enter plant name"
            value={name}
            onChange={(evt) => setName(evt.target.value)}
          />
        </Form.Item>

        <Form.Item label={"Plant Category"}>
          <Select value={type} onChange={(val) => setType(val)}>
            {plantTypes.map((type) => (
              <Select.Option value={type} key={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
