import { useState } from "react";
import generateAPI from "../utils/api";
import { Form, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";

interface props {
  handleClose: () => void;
  onComplete: () => void;
}

export default function AddTipModal({ handleClose, onComplete }: props) {
  const [content, setContent] = useState("");
  const api = generateAPI();

  return (
    <Modal
      visible={true}
      onCancel={handleClose}
      title={"Add Tip"}
      onOk={async () => {
        if (!content) {
          return;
        }

        await api.admin.newTip.post({ content });

        onComplete();
        handleClose();
      }}
    >
      <Form>
        <Form.Item label={"Tip"}>
          <TextArea
            placeholder="Enter tip"
            value={content}
            onChange={(evt) => setContent(evt.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
