import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PlantListPage } from "./PlantListPage";
import { TipsPage } from "./TipsPage";
import { UserListPage } from "./UserListPage";
import {DiscoverPage} from "./DiscoverPage";
import {VideoPage} from "./VideoPage";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PlantListPage />} />
        <Route path="/tips" element={<TipsPage />} />
        <Route path="/users" element={<UserListPage />} />
        <Route path="/discover" element={<DiscoverPage />} />
        <Route path="/videos" element={<VideoPage />} />
      </Routes>
    </BrowserRouter>
  );
}
