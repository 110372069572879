import { Header } from "./components/Header";
import useAccount from "./hooks/useAccount";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import {UserList} from "./components/UserList";

export function UserListPage() {
  const { token, dialog, logout } = useAccount();

  return (
    <Layout>
      {dialog}
      {token && (
        <>
          <Header selected={"users"} />
          <Content style={{ padding: 20 }}>
            <Title level={3}>User List</Title>
            <UserList />
          </Content>
        </>
      )}
    </Layout>
  );
}
