import { useState } from "react";
import styled from "styled-components";
import AddPlantModal from "./AddPlantModal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import generateAPI from "../utils/api";
import { Button, Popconfirm, Space, Table } from "antd";
import Search from "antd/es/input/Search";

const Container = styled.div``;

const api = generateAPI();

interface UserDataObject {
  _id: string;
  username: string;
}

export function UserList() {
  const [search, setSearch] = useState("");
  const [addPlantVisible, setAddPlantVisible] = useState(false);
  const queryClient = useQueryClient();

  const { refetch, data: users } = useQuery("users", () =>
    api.admin.listUsers.get()
  );

  // const removePlantMutation = useMutation(
  //   ({ id }) =>
  //     api.admin.removePlant.post({
  //       id,
  //     }),
  //   {
  //     onSuccess: async () => {
  //       await queryClient.invalidateQueries(["users"]);
  //     },
  //   }
  // );

  const searchFilter = new RegExp(search, "i");
  const filtered = users
    ?.filter((user: UserDataObject) => searchFilter.test(user.username))
    .sort((a: UserDataObject, b: UserDataObject) => {
      if (a.username.toLowerCase() < b.username.toLowerCase()) {
        return -1;
      }
      if (a.username.toLowerCase() > b.username.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  const columns = [
    {
      title: "ID",
      key: "_id",
      dataIndex: "_id",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: (a: UserDataObject, b: UserDataObject) => {
        if (a.username.toLowerCase() < b.username.toLowerCase()) {
          return -1;
        } else {
          return 1;
        }
      },
    },
    {
      title: "E-mail",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Controls",
      key: "_id",
      dataIndex: "_id",
      render: (id: string, row: UserDataObject) => (
        <>
          <Popconfirm
            title={`Are you sure you wish to remove ${row.username}?`}
            okText={"Yes"}
            onConfirm={async () => {
              // await removePlantMutation.mutate({ id });
            }}
          >
            <Button danger={true} onClick={() => {}}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <Container>
      {addPlantVisible && (
        <AddPlantModal
          onComplete={refetch}
          handleClose={() => setAddPlantVisible(false)}
        />
      )}

      <Space>
        <Search
          placeholder={"Search"}
          value={search}
          onChange={(evt) => setSearch(evt.target.value)}
        />
        <Button type={"primary"} onClick={() => setAddPlantVisible(true)}>
          Add plant
        </Button>
      </Space>

      <Table rowKey={"_id"} dataSource={filtered} columns={columns} />
    </Container>
  );
}
